require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// tailwind
require('stylesheets/application')

// stimulus
import "controllers"

// fontawesome
import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faTwitter, faLinkedinIn, faInstagram, faFacebookF, faYoutube, faSkype } from '@fortawesome/free-brands-svg-icons'
import { faEnvelope, faMinus, faPlus  } from '@fortawesome/free-solid-svg-icons'

library.add(fas, faTwitter)
library.add(fas, faLinkedinIn)
library.add(fas, faInstagram)
library.add(fas, faFacebookF)
library.add(fas, faYoutube)
library.add(fas, faSkype)
library.add(fas, faEnvelope)
library.add(fas, faMinus)
library.add(fas, faPlus)

dom.watch()