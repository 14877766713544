import { Controller } from "stimulus"
import Glide, { Controls, Breakpoints, Autoplay  } from '@glidejs/glide/dist/glide.modular.esm'

export default class extends Controller {
  connect() {
    this.initSlider()
  }

  initSlider() {
    const header = new Glide('.header-carousel', {
      hoverpause: true,
      autoplay: 5000,
      perView: 1
    }).mount({ Controls, Breakpoints, Autoplay })

    header.mount({ Controls, Breakpoints })
    new Glide('.testimonial-carousel').mount({ Controls, Breakpoints })
  }
}
