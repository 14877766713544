import { Controller } from "stimulus"

export default class extends Controller {
  onToggleQuestion(event) {
    const clickedElement = event.currentTarget.parentNode.parentNode
    
    if (clickedElement.classList.contains('active')) {
      clickedElement.classList.remove('active')
    } else {
      const currentActive = document.querySelectorAll('.faq__container.active')
      currentActive.forEach(c => c.classList.remove('active'))

      clickedElement.classList.add('active')
    }
  }
}
