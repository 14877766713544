import { Controller } from "stimulus"
// Email obfuscator script 2.1 by Tim Williams, University of Arizona
// Random encryption key feature coded by Andrew Moulden
// This code is freeware provided these four comment lines remain intact
// A wizard to generate this code is at http://www.jottings.com/obfuscator/

export default class extends Controller {
  connect() {
    this.obfuscateEmails()
  }

  obfuscateEmails() {
    const coded = "2CB87C@r84z3UT.Bh.ar"
    const key = "FKcGmPaeRdM7oLwU3A9sODBv5W8C1gtXhk2fxI04VNZEpjlyuTHqzrYbSniQ6J"
    const shift = coded.length
    let link = ""

    for (let i=0; i<coded.length; i++) {
      if (key.indexOf(coded.charAt(i))==-1) {
        const ltr = coded.charAt(i)
        link += (ltr)
      }
      else {     
        const ltr = (key.indexOf(coded.charAt(i))-shift+key.length) % key.length
        link += (key.charAt(ltr))
      }
    }

    const href = `mailto:${link}`
    const aTags = document.querySelectorAll('.encode__mailto')

    aTags.forEach(tag => {
      tag.href = href
      tag.innerText = link
    })
  }
}
