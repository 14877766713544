import { Controller } from "stimulus"
import Cookies from 'js-cookie'

export default class extends Controller {
  static targets = ['banner']

  connect() {
    this.initGA()
  }

  onReject() {
    this.bannerTarget.classList.add('hidden')
    Cookies.set('has_seen_banner', 'true')
  }

  onAccept() {
    this.bannerTarget.classList.add('hidden')
    Cookies.set('has_seen_banner', 'true')
    Cookies.set('allow_cookies', 'true')
    this.initGA()
  }

  initGA() {
    if (Cookies.get('allow_cookies')) {
      (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
      m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
      })(window,document,'script','//www.google-analytics.com/analytics.js','ga');
      ga('create', this.bannerTarget.dataset.ga, 'auto');
      ga('send', 'pageview');
    }
  }
}
