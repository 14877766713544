import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['shareContainer', 'commentsContainer']

  connect() {
    this.initShareButtons()
  }

  initShareButtons() {
    this.shareContainerTarget.dataset.url = `${location.origin}${this.shareContainerTarget.dataset.url}`
    require('share-buttons/dist/share-buttons')
  }

  toggleComments() {
    if (this.commentsContainerTarget.classList.contains('hidden')) {
      this.commentsContainerTarget.classList.remove('hidden')
    } else {
      this.commentsContainerTarget.classList.add('hidden')
    }
  }
}
